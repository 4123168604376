import { Application } from '@hotwired/stimulus';

const application = Application.start();

import OffCanvasController from './controllers/offcanvas_controller';
application.register('offcanvas', OffCanvasController);

import AnimatedHeadingController from './controllers/animated-heading_controller';
application.register('animated-heading', AnimatedHeadingController);

import StickyController from './controllers/sticky_controller';
application.register('sticky', StickyController);