import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import getRect from '../utilities/getRect';
import getElementProp from '../utilities/getElementProp';
export default class extends Controller {
  static targets = ['container', 'topBar', 'endState', 'buttonIcon', 'buttonIconLineTop', 'buttonIconLineMiddle', 'buttonIconLineBottom', 'menuItem'];

  connect() {
    gsap.registerPlugin(CSSPlugin);

    gsap.set(this.containerTarget, { visibility: 'visible' });

    this.tl = gsap.timeline();
    this.animateButtonIcon();

    const bp = getElementProp(document.documentElement, '--offcanvas-endstate-bp');

    this.endStateElementVisible = window.matchMedia(`(min-width: ${bp})`).matches;

    this.isMobile = !window.matchMedia(`(min-width: 48em)`).matches;

    this.isOpen = false;

    this.bodyElement = document.querySelector('body');
  }

  getEndState() {
    if (!this.endStateElementVisible) return '100%';

    const endStateRect = getRect(this.endStateTarget);
    const padding = getElementProp(this.containerTarget, '--offcanvas-padding');

    const endState = `calc(${window.innerWidth - endStateRect.x}px + ${padding})`;

    return endState;
  }

  toggle(e) {

    if (this.isOpen) {
      this.bodyElement.classList.remove('u-width-100%');
      this.tl.reverse();
      this.close();
    }
    else {
      this.bodyElement.classList.add('u-width-100%');
      this.tl.play();
      this.open();
    }

    this.isOpen = !this.isOpen;
  }

  open() {
    const topBarRect = getRect(this.topBarTarget);
    this.buttonIconTarget.classList.add('offcanvas-icon-open');

    const maxWidth = this.getEndState();

    this.containerTarget.style.setProperty('--offcanvas-max-width', maxWidth);

    this.endStateTarget.classList.add('u-color-black');
    this.containerTarget.classList.add('-open');

    if (this.isMobile) {
      this.topBarTarget.classList.add('top-bar-fixed-position');
      gsap.set(this.topBarTarget, { position: 'fixed', y: topBarRect.y, width: topBarRect.width });
    }
  }

  close() {
    this.endStateTarget.classList.remove('u-color-black');
    this.buttonIconTarget.classList.remove('offcanvas-icon-open');
    this.containerTarget.classList.remove('-open');

    if (this.isMobile) {
      this.topBarTarget.classList.remove('top-bar-fixed-position');
      gsap.set(this.topBarTarget, { clearProps: 'width,transform,position' });
    }
  }

  animateButtonIcon() {
    const topLineRect = getRect(this.buttonIconLineTopTarget);
    const middleLineRect = getRect(this.buttonIconLineMiddleTarget);
    const bottomLineRect = getRect(this.buttonIconLineBottomTarget);

    const duration = 0.2;
    const ease = 'power1.inOut';

    this.tl.pause();

    this.tl.to(this.buttonIconLineTopTarget, { css: { y: (middleLineRect.y - topLineRect.y + 1) }, duration: duration, ease: ease });
    this.tl.to(this.buttonIconLineBottomTarget, { css: { y: (bottomLineRect.y - middleLineRect.y + 1.5) * -1 }, duration: duration, ease: ease }, `-=${duration}`);
    this.tl.to(this.buttonIconLineMiddleTarget, { css: { scaleX: 0 }, duration: 0 });

    this.tl.to(this.buttonIconLineTopTarget, { css: { rotation: 45, scaleX: 1.1, transformOrigin: 'center center' }, duration: duration, ease: ease });
    this.tl.to(this.buttonIconLineBottomTarget, { css: { rotation: -45, scaleX: 1.1, transformOrigin: 'center center' }, duration: duration, ease: ease }, `-=${duration}`);
  }
}