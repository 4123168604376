import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import getRect from '../utilities/getRect';
import prefersReducedMotion from '../utilities/prefersReducedMotion';
import enableAnimationBreakpoint from '../utilities/enableAnimationBreakpoint';

export default class extends Controller {
	static targets = ['container', 'fromRight', 'fromLeft'];

	connect() {
		gsap.registerPlugin(ScrollTrigger);
    
		const reduceMotion = prefersReducedMotion();
		const enableAnimation = enableAnimationBreakpoint();

		if (!reduceMotion && enableAnimation) {
			this.animateHeading();
		}
		else {
			this.containerTarget.classList.add('animated-heading-disabled');
		}
	}

	animateHeading() {
		const lazyImages = document.querySelectorAll('[loading="lazy"]');
		const animationName = this.containerTarget.dataset.animatedHeadingName;
		const rightRect = this.hasFromRightTarget ? getRect(this.fromRightTarget) : false;
		const leftRect = this.hasFromLeftTarget ? getRect(this.fromLeftTarget) : false;

		const states = {
			'default': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				},
				'left': {
					'start': (leftRect.width * 0.3) * -1,
					'end': 0
				},
			},
			'letter': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'esophageal-conclusion': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'pride-conclusion': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'pillsbury-conclusion': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'pheasants-conclusion': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'quote-top': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				}
			},
			'quote-bottom': {
				'left': {
					'start': (leftRect.width * 0.3) * -1,
					'end': (leftRect.width * 0.1) * -1,
				},
			},
			'stats': {
				'right': {
					'start': window.innerWidth,
					'end': 0
				},
				'left': {
					'start': (leftRect.width * 0.3) * -1,
					'end': 0
				},
			},
		};

    
		if(this.hasFromRightTarget) {
			gsap.set(this.fromRightTarget, { x: states[animationName].right.start });
    
			gsap.to(this.fromRightTarget, {
				scrollTrigger: {
					trigger: this.fromRightTarget,
					scrub: true,
					end: 'top top'
				},
				x: states[animationName].right.end
			});
		}

		if(this.hasFromLeftTarget) {
			gsap.set(this.fromLeftTarget, { x: states[animationName].left.start });

			gsap.to(this.fromLeftTarget, {
				scrollTrigger: {
					trigger: this.fromLeftTarget,
					scrub: true,
					end: 'top top'
				},
				x: states[animationName].left.end
			});
		}

		lazyImages.forEach(img => {
			img.addEventListener('load', () => {
				ScrollTrigger.refresh();
			});
		});
	}
}