import { Controller } from "@hotwired/stimulus";
import mobileBreakpoint from "../utilities/mobileBreakpoint";
export default class extends Controller  {

  static targets = ['stickyElement'];

  connect() {
    this.stickyClass = '-is-stuck';
    this.hiddenClass = '-is-hidden';
    this.hideHeaderPos = 100;

    this.isMobile = mobileBreakpoint();

    this.previousScrollYPos = window.scrollY;

    document.addEventListener("scroll", this.scrollListener.bind(this), { passive: true });
  }


  scrollListener(e) {
    //SCROLLING DOWN
    if (this.previousScrollYPos < window.scrollY) {
      if (this.previousScrollYPos === 0) {
        this.stickyElementTarget.classList.add(this.stickyClass);
      }

      if ((window.scrollY > this.hideHeaderPos) && this.isMobile) {
        this.stickyElementTarget.classList.add(this.hiddenClass);
      }
    }
    //SCROLLING UP
    else {
      this.stickyElementTarget.classList.remove(this.hiddenClass);

      if (window.scrollY === 0) {
        this.stickyElementTarget.classList.remove(this.stickyClass);
      }
    }

    this.previousScrollYPos = window.scrollY;
  }
}